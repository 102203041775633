.mainContainer {
  display: flex;
  justify-content: center;
  color: white;
  background-color: #0a0c0a;
  /* padding: 20px; */
  border-radius: 8px;
  width: 100%;
  max-width: 34.896vw;
  /* margin-left: 65px; */
  /* margin-top:65px; */
  /* margin: auto; */
  /* font-family: "Arial", sans-serif; */

  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  flex: 1 1 45%;
}

.subContainer {
  width: 100%;
  max-width: 34.896vw;
}
.overviewHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.headerContainer {
  display: flex;
  align-items: center;
}

.headerText {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.headerContainer img {
  height: 21px;
  width: 21px;
  margin-left: 20px;
  /* margin-bottom: 4px; */
}

.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;

  /* border-radius: 15px; */
  /* border: 1px solid rgba(53, 58, 51, 0); */
  /* background: #121410; */
}

.dropdownContainer .dropdown {
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  /* width: 100%; */
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

.sortIcon.inverted {
  transform: rotate(180deg);
}

.tableContainer {
  display: flex;

  max-height: 100%;
  overflow-y: scroll;
  position: relative;
}

.tableContainer.largeTable {
  max-height: 300px;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 15px;
}

/* th, */
.table td {
  padding: 11px 31px;
  text-align: left;
  color: #fff;

  /* font-family: 'Exo 2'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
}

.table th {
  color: #bbb;
  font-weight: 500;
  padding: 0px 31px;
  text-align: left;
  font-size: 14px;
  opacity: 0.5;

  /* width: 100%; */
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #0a0c0a;
}

.table th:last-child {
  color: #bbb;
  font-weight: 500;
  padding-right: 0px;
  /* width: 100%; */
}
.table tbody tr {
  border: 1px solid #333;
}

.tableRow {
  background-color: rgba(99, 99, 99, 0.1);
  border-radius: 20px;
  /* margin-bottom: 8px; */
}
.tableRow td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-left: 30px;
}

.tableRow td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-right: 35px;
}
.rankIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-weight: bold;
}

.rank1,
.rank2,
.rank3 {
  background-color: #ffd700;
  color: #000;
}

.symbolCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.symbolIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
}

.btc {
  background-color: #f7931a;
}
.eth {
  background-color: #627eea;
}
.sol {
  background-color: #00ffa3;
}

.positive {
  color: #00ff00 !important;
}
.negative {
  color: #ff0000 !important;
}

@media (max-width: 1750px) {
  .mainContainer {
    max-width: 35.286vw;
  }
  .subContainer {
    max-width: 35.286vw;
  }

  .overviewHeader {
    margin-bottom: 20px;
  }

  .headerContainer {
    display: flex;
    align-items: center;
  }

  .headerText {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .headerContainer img {
    height: 21px;
    width: 21px;
    margin-left: 20px;
  }

  .sortIcon.inverted {
    transform: rotate(180deg);
  }

  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 15px;
  }

  /* th, */
  .table td {
    padding: 11px 20px;
    text-align: left;
    color: #fff;

    /* font-family: 'Exo 2'; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 112.5%; /* 112.5% */
  }

  .table th {
    color: #bbb;
    font-weight: 500;
    padding: 0px 20px;
    text-align: left;
    font-size: 14px;
    opacity: 0.5;

    /* width: 100%; */
  }
}

@media (max-width: 1640px) {
  .dropdownContainer .dropdown {
    display: flex;
    align-items: center;
    background-color: #121410;
    color: #fff;
    padding: 10px 27px;
    cursor: pointer;
    border-radius: 13px;
    position: relative;
    z-index: 3;
  }

  .headerText {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

@media (max-width: 1570px) {
  .table td {
    padding: 11px 15px;
    text-align: left;
    color: #fff;

    /* font-family: 'Exo 2'; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 112.5%; /* 112.5% */
  }

  .table th {
    color: #bbb;
    font-weight: 500;
    padding: 0px 15px;
    text-align: left;
    font-size: 14px;
    opacity: 0.5;

    /* width: 100%; */
  }

  .table td:first-child {
    padding-left: 15px;
  }
}

@media (max-width: 1415px) {
  .mainContainer {
    max-width: 100%;
    flex: 1 1 100%;
  }
  .subContainer {
    max-width: 100%;
  }
}

@media (max-width: 620px) {
  .headerText {
    font-size: 22px;
    text-align: left;
    line-height: 24px;
    /* width:min-content; */
    align-items: center;
  }
  .overviewHeader {
    align-items: center;
  }
}
