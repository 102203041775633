.avatar {
  width: 25px;
  height: 25px;
  padding: 5px;
  background: var(--dim-green);
  color: white; 
  border: 2px solid var(--border-color);
  border-radius: 50%; 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; 
  font-weight: bold;
  text-transform: uppercase;
}

.avatarLogo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}