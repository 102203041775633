.modalOverlay {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  padding: 1px;
  border-radius: 30px;
  font-family: 'Exo 2';
}

.modalContent {
  padding: 70px 100px;
  border-radius: 10px;
  text-align: center;

  justify-content: center;
  background: #0a0c0a;
  border-radius: 30px;
}

.title {
  color: white;
  font-size: 24px;
  font-weight: 600;

  padding-bottom: 10px;
  /* Reduced padding-bottom */
}

.subtitle {
  /* margin-top: 2px; */
  /* Reduced margin-top */
  /* margin-bottom: 20px; */
  /* Added margin-bottom for spacing */
  margin: 10px 0px 15px 0px;
  font-size: 20px;
  font-weight: 400;

  color: #caff00;
}

.buttonWallet {
  width: 100%;
  max-width: 120px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #22251f;
  color: #caff00;
  padding: 12px;
  font-family: 'Exo 2';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
  column-gap: 10px;
}

.buttonWallet:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

.tooltipContainer {
  position: relative;
  text-decoration: none;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #22251f;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;

  /* Position the tooltip */
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);

  /* Add smooth transition */
  opacity: 0;

  line-height: 1.4;
  transition: opacity 0.2s ease-in-out;
}

/* Add arrow to tooltip */
.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

/* Show tooltip on hover */
.tooltipContainer:hover .tooltip {
  visibility: visible;
  opacity: 1;
  max-width: 600px;
  white-space: normal;
  word-wrap: break-word;
  width: 300px;
}

.socialMedia{
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.socialMedia a {
  display: inline-block;
}

.socialMedia a:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

.referralInputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (max-width: 800px) {
  .title {
    font-size: 20px;
  }

  .modalContent {
    padding: 70px 70px;
  }

  .subtitle {
    /* margin-top: 2px; */
    /* Reduced margin-top */
    /* margin-bottom: 20px; */
    /* Added margin-bottom for spacing */
    margin: 10px 0px 10px 0px;
    font-size: 16px;
   
  }
}

@media (max-width: 580px) {
  .modalOverlay{
    width: 80%;
  }
  .title {
    font-size: 20px;
  }

  .modalContent {
    width: 100%;
    padding: 70px 50px;
  }
  .address{
    word-break: break-all;
  }
}

.loaderContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  height: 20px;
  position: relative;
}

.referralInput.validated {
  background: rgba(21, 24, 20, 0.8);
  border-color: #4CAF50;
}

.validatedMessageContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.validatedMessage {
  color: #4CAF50;
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  text-align: center;
  justify-content: center;
}

.removeButton {
  background: #ff4444;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.removeButton:hover:not(:disabled) {
  background: #ff6666;
}

.removeButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}



.buttonLogOut {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  /* width: 90px;
    height: 40px; */
  padding: 12px;
  border-radius: 15px;
  background: #22251f;
  color: rgb(255, 255, 255, 0.5);
  /* opacity: 0.5; */
  column-gap: 10px;
  /* font-family: 'Exo 2'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  
}

.buttonWallet:hover,
.buttonLogOut:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

@media (max-width: 1850px) {
  .buttonWallet {
    width: 100%;
    max-width: 120px;
  }
  .buttonLogOut {
    width: 100%;

    max-width: 120px;
  }
}
