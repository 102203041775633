.apiModalInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333;
  color: white;
}

.deleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #22251f;
  color: red;
  padding: 7px;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  column-gap: 10px;
}

.comingSoon {
  display: flex;
  justify-content: start;
  align-items: start;
  border-radius: 15px;
  background: #22251f;
  color: #caff00;
  padding: 5px;
  font-family: "Exo 2";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  column-gap: 10px;
}

.deleteButton:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

.generatekeyButton {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #22251f;
  color: #caff00;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 25px;
  column-gap: 10px;
}

.buttonWallet {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #22251f;
  color: #caff00;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 25px;
  column-gap: 10px;
}

.buttonWallet img {
}

.buttonWallet:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

.apikeyCard {
  justify-content: unset;
}

.apiContainer {
  color: white;
  background-color: #0a0c0a;
  border-radius: 8px;
  font-family: "Exo 2";
  width: 100%;
  max-width: 1500px;
}

.title {
  font-size: 40px;
  font-weight: 400;
  line-height: 100%;
  text-align: left;
}

.modalOverlay {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  padding: 1px;
  border-radius: 30px;
  font-family: "Exo 2";
}

.modalContent {
  padding: 70px 100px;
  border-radius: 30px;
  text-align: center;
  justify-content: center;
  background: #0a0c0a;
}

.modalTitle {
  color: white;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.refreshButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #22251f;
  color: #caff00;
  padding: 7px;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  column-gap: 10px;
}

.refreshButton:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

.cardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* width: 49%; */
}
.buttonContainer {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.addressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
}

.loaderContainer {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
.card {
  background-color: rgba(99, 99, 99, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.cardHeader {
  display: flex;
  justify-content: end;
  align-items: center;
}

.copyIcon {
  cursor: pointer;
  color: #caff00;
  position: relative;
}

.apiKey {
  font-size: 14px;
}

.expiryDate,
.usage {
  font-size: 14px;
  color: #bbb;
  margin-top: 5px;
}

.toggleVisibility {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  color: #caff00;
}

.emptyCard {
}

.emptyOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  backdrop-filter: blur(1px);
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.tooltip {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #444;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 100;
}

.name {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.textDiv {
  text-align: left;
  margin-top: 30px;
  font-family: "Exo 2";
  padding: 30px 30px;
  background-color: rgba(99, 99, 99, 0.1);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.flexContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: stretch;
  row-gap: 20px;
  width: 100%;
  column-gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.planDetails {
  margin-bottom: 20px;
}

.planTitle {
  font-size: 24px;
  font-weight: 600;
}

.progressContainer {
  margin-bottom: 20px;
}

.progressDetails {
  display: flex;
  justify-content: space-between;
}

.progressBar {
  background-color: #444;
  border-radius: 5px;
  height: 10px;
  margin-top: 5px;
  position: relative;
}

.progress {
  background-color: #caff00;
  width: 80%;
  height: 100%;
  border-radius: 5px;
}

.progressText {
  font-size: 12px;
  color: #bbb;
  margin-top: 5px;
}

@media (max-width: 1640px) {
  .title {
    font-size: 32px;
    font-weight: 400;
    line-height: 100%;
    text-align: left;
  }
  .card {
    min-height: 200px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 28px;
  }
  .textDiv {
    padding: 30px 20px;
  }
  .flexContainer {
    flex-direction: column;
    justify-content: center;
  }
  .cardContainer {
    /* width: 50%; */
  }
  .card {
    margin: 10px 0;
  }
  .apiContainer {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .toggleVisibility {
    margin-top: 2px;
    bottom: 10px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 32px;
  }
  .textDiv {
    padding: 30px 10px;
  }
}

@media (min-width: 1930px) {
  .card {
    min-height: 300px;
  }
}

.subscriptionInfo {
  font-size: 13px;
  line-height: 1.4;
  margin-top: 15px;
  color: #bbb;
}

.subscriptionInfo strong {
  color: #caff00;
  word-break: break-all;
}

.refCodeSection {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
}

.refCodeDisplay {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.refLabel {
  font-size: 14px;
  color: #888;
}

.refCode {
  font-size: 18px;
  font-weight: 600;
  color: #00ff00;
  font-family: monospace;
}

.refStats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 20px;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.statLabel {
  font-size: 14px;
  color: #888;
}

.statValue {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  text-align: center;
}

.noRefCode {
  text-align: center;
  padding: 20px 0;
}

.createRefButton {
  background: #caff00;
  color: #000;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 12px;
}

.createRefButton:hover {
  opacity: 0.8;
}

.refCodeWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.copyButton {
  background: transparent;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.copyButton:hover {
  background: rgba(255, 255, 255, 0.1);
}

.copyIcon {
  width: 16px;
  height: 16px;
  opacity: 0.7;
}

.copyButton:hover .copyIcon {
  opacity: 1;
}

.codeActions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.deleteButton:hover {
  opacity: 1;
}

.deleteIcon {
  width: 16px;
  height: 16px;
  filter: invert(1);
}

.dialogButton {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;
  transition: opacity 0.2s;
}

.dialogButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cancelButton {
  background: #333;
  color: white;
}

.deleteButton {
  background: #ff4444;
  color: white;
}

.cancelButton:hover {
  background: #444;
}

.deleteButton:hover:not(:disabled) {
  background: #ff6666;
}

.referralInputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.referralInput.validated {
  background: rgba(21, 24, 20, 0.8);
  border-color: #4caf50;
}

.validatedMessageContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.validatedMessage {
  color: #4caf50;
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  text-align: center;
  justify-content: center;
}

.removeButton {
  /* background: #ff4444; */
  color: white;
  border: none;
  /* padding: 5px 10px; */
  border-radius: 4px;
  cursor: pointer;
  height: 20px;
  width: 20px;

  /* font-size: 5px; */
  /* transition: background-color 0.2s; */
}

.removeButton:hover:not(:disabled) {
  background: #ff6666;
}

.removeButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.subtitle {
  color: #a0ff00;
}

.tooltipContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}
