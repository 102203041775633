.mainContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  width: 100%;
  max-width: 620px;
  /* margin-top: 65px; */
  font-family: "Exo 2";

}
.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}
.headingContainer {
  display: flex;
  align-items: center;
}

.headingContainer img {
  margin-left: 16px;
  margin-top: 6px;
}

.dropdownsContainer{
  display: flex;
  gap: 10px;
}

.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;

  /* border-radius: 15px; */
  /* border: 1px solid rgba(53, 58, 51, 0); */
  /* background: #121410; */
}

.dropdownContainer .dropdown {
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 0px 26px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  /* width: 100%; */
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

.gaugeContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
}

.mask {
  position: absolute;
  bottom: -5px;
}
.heading {
  color: #fff;

  /* font-family: "Arial", sans-serif; */
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@media (max-width: 1850px) {
  .gaugeContainer {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
  }
}

@media (max-width: 1650px) {
  .mainContainer {
    max-width: 540px;
  }

  .headingContainer img {
    margin-left: 16px;
    margin-top: 0px;
  }

  .heading {
    color: #fff;

    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .dropdownContainer .dropdown {
    display: flex;
    align-items: center;
    background-color: #121410;
    color: #fff;
    padding: 10px 26px;
    cursor: pointer;
    border-radius: 13px;
    position: relative;
    z-index: 3;
  }
  .gaugeContainer {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .mask {
    width: 120%;
    position: absolute;
    bottom: -5px;
  }
}

@media (max-width: 1540px) {
  .mainContainer {
    max-width: 480px;
  }
  .gaugeContainer {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .mask {
    width: 120%;
    position: absolute;
    bottom: -5px;
  }
}

@media (max-width: 1415px) {
  .mainContainer {
    max-width: 100%;
  }
}

@media (max-width: 620px) {
  .heading {
    font-size: 22px;
  }
}

@media (max-height: 1100px) {
 .topContainer{
  margin-bottom: 30px;
 }
}

@media (max-height: 820px) {
 .topContainer{
  margin-bottom: 15px;
 }
}