.mainContainer {
  display: flex;
  justify-content: center;
  color: white;
  background-color: #0a0c0a;
  /* padding: 20px; */
  border-radius: 8px;
  min-width: 100%;
  max-width: 1920px;
  /* margin-left: 65px; */
  /* margin-top:65px; */
  /* margin: auto; */
  /* font-family: "Arial", sans-serif; */
  font-family: "Exo 2";
}

.subContainer {
  min-width: 100%;
}
.headerLayout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.headerContainer {
  display: flex;
  align-items: center;
}

.headerText {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.headerContainer img {
  height: 21px;
  width: 21px;
  margin-left: 20px;
  /* margin-bottom: 4px; */
}

.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;
  max-width: 118px;
  max-height: 41px;

  /* border-radius: 15px; */
  /* border: 1px solid rgba(53, 58, 51, 0); */
  /* background: #121410; */
}

.dropdownContainer .dropdown {
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 0px 26px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  /* width: 100%; */
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

.dashboard {
  margin-top: 30px;
  display: flex;

  justify-content: space-between;
  gap: 20px;
  align-items: stretch;
  color: #ffffff;
}

.panel {
  flex: 1;
  min-width: 300px;
  /* max-width: calc(33.33% - 20px);  */
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.panelTitle {
  text-align: left;
  font-size: 22px;
  font-weight: 400;
}
.panelContent {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dataRow {
  display: flex;
  justify-content: space-between;
  background: rgba(99, 99, 99, 0.1);
  padding: 10px 15px;
  border-radius: 15px;
}

.dataLabel {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.dataValue {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.explanationDiv {
  margin-top: 15px;
  background-color: rgba(99, 99, 99, 0.1);
  border-radius: 15px;
  padding: 0px 20px;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
}

.explanation {
  color: #fff;
  font-style: normal;
  font-weight: 500;
  text-align: left;
}

@media (max-width: 1750px) {
  .mainContainer {
    max-width: 35.286vw;
  }
  .subContainer {
    max-width: 35.286vw;
  }

  .headerContainer {
    display: flex;
    align-items:  center;
    margin-bottom: 20px;
  }

  .headerText {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .headerContainer img {
    height: 21px;
    width: 21px;
    margin-left: 20px;
  }
}

@media (max-width: 1640px) {
  .headerText {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

@media (max-width: 1415px) {
  .mainContainer {
    max-width: 100%;
  }
  .subContainer {
    max-width: 100%;
  }
}

@media (max-width: 1300px) {
  .dashboard {
    flex-wrap: wrap;
  }
}

@media (max-width: 620px) {
  .headerText {
    font-size: 22px;
    text-align: left;
    line-height: 24px;
    /* width:min-content; */
    align-items: center;
  }
  .overviewHeader {
    align-items: center;
  }

  .panelTitle {
    font-size: 20px;
  }
}
