.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1000; */
  border-radius: 10px;
}

.blurredBackground {
  filter: blur(3px);
  pointer-events: none;
  user-select: none;
}

.modal {
  /* background: #1a1a1a; */
  /* padding: 2rem;
  border-radius: 8px;
  text-align: center;
  color: white;
  border: 1px solid #333;
  max-width: 400px;
  width: 90%; */
}

.connectButton {
  border-radius: 10px;
  background: #22251f;
  color: #caff00;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 25px;
  column-gap: 10px;
}

.connectButton:hover {
  filter: brightness(1.5);
  cursor: pointer;
}
