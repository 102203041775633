.mainContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 350px;
  max-width: 34.9999vw;
  flex: 1 1 45%;
  font-family:"Exo 2";
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
  /* padding-left: 2.969vw;
  padding-top: 33px;
  padding-bottom: 40px; */
  z-index: 1;
  /* background: url('../../public/images/circleBG.png'); */
}
.circleBG {
  position: absolute;
  /* top: -30px; */

  height: 100%;
  width: 105%;
}
.circleBG img {
  width: 100%;
  height: 100%;
}

.circleContainer {
  padding-left: 57px;
  height: 250px;
  display: flex;
  align-items: center;
}

.overviewHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.headerContainer {
  display: flex;
  align-items: center;
}

.headerText {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.headerContainer img {
  height: 21px;
  width: 21px;
  margin-left: 20px;
  /* margin-bottom: 4px; */
}

.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;

  /* border-radius: 15px; */
  /* border: 1px solid rgba(53, 58, 51, 0); */
  /* background: #121410; */
}

.dropdownContainer .dropdown {
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  /* width: 100%; */
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

@media (max-width: 1640px) {
  .headerText {
    color: #fff;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .mainContainer {
    min-height: 270px;
  }
}

@media (max-width: 1415px) {
  .mainContainer {
    max-width: 100%;
  }

  .circleContainer {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1280px) {
  .mainContainer {
    flex: 1 1 100%;
  }

  .circleContainer {
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
}

@media (min-width: 1920px) {
  .mainContainer {
    max-height: 380px;
  }
  .subContainer {
    /* row-gap: 50px; */
  }
  .circleContainer {
  }
}

@media (max-width: 620px) {
  .headerText {
    font-size: 22px;
    text-align: left;
    line-height: 24px;
    /* width:min-content; */
  }
}
