.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 12px 0;
    width: 100%;
  }
  
  .socialIcon {
    height: 30px;
    width: 30px;
    background: #151814;
    padding: 10px;
    border-radius: 50%;
  }
  
  .docsIcon {
    height: 50px;
    width: 50px;
  }
  
  a:hover {
    filter: brightness(1.5);
    cursor: pointer;
  }
  
  @media (max-width: 1850px) {
    .container {
      max-width: 120px;
      margin: 0 auto;
    }
  }