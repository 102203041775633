.tooltip {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  max-width: 200px;
}

.tooltip h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.tooltip ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tooltip li {
  margin-bottom: 5px;
}