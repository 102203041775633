.adsWrapper img {
  width: 100%;
  height: 100%;
}

.error {
  display: none;
}

.gradient {
  border-radius: 15px;
  padding: 2px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  /* margin-top: 15px; */
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 250px;
}
.adsWrapper {
  border-radius: 15px;
  background-color: #121410;
}
.adsWrapper img {
  border-radius: 15px;
  
}

.textDiv {
  text-align: left;
  padding: 16px 12px;
  color: #ffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
