.modalOverlay {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right, #353a33 0%, #121410 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
    padding: 1px;
    border-radius: 30px;
    font-family: "Exo 2";
  
  }
  
  .modalContent {
    padding: 70px 100px;
    border-radius: 10px;
    text-align: center;
  
    justify-content: center;
    background: #0a0c0a;
    border-radius: 30px;
  }
  
  .title {
  
    color: white;
    font-size: 24px;
    font-weight: 600;
  
    padding-bottom: 10px;
    /* Reduced padding-bottom */
  }
  
  
  .subtitle {
  
    margin-top: 2px;
    /* Reduced margin-top */
    margin-bottom: 20px;
    /* Added margin-bottom for spacing */
    font-size: 20px;
    font-weight: 400;
  
    color: #caff00;
  
  }
  
  .buttonWallet {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: #22251f;
    color: #caff00;
    padding: 12px;
    font-family: "Exo 2";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 100% */
    column-gap: 10px;
  }
  
  .buttonWallet:hover {
    filter: brightness(1.5);
    cursor: pointer;
  }

  .socialMedia{
  display: flex;
  margin-top: 20px;
  column-gap: 20px;
  justify-content: center;
}


.socialMedia a {
  display: inline-block;
}

.socialMedia a:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

  
  @media (max-width: 800px) {
    .title {
      font-size: 20px;
    }
  
    .modalContent {
      padding: 70px 70px;
    }
  }
  
  @media (max-width: 580px) {
    .title {
      font-size: 20px;
    }
  
    .modalContent {
      width: 200px;
      padding: 70px 50px;
    }
  }


  .loaderContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    height: 20px;
    position: relative;
  }