.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  /* background-color: #000; */
  color: #fff;
}

.subContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
}

.welcomeMessage {
  color: #fff;

  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;

  line-height: 61.075px; /* 203.582% */
}

.highlight {
  color: #a0ff00; /* Adjust the color as needed */
}

.rightContainer {
  display: flex;
  align-items: center;
}

.profile {
  display: flex;
  /* align-items: center; */
  row-gap: 5px;
  margin-right: 43px;
}

.profilePic {
  width: 63px;
  height: 63px;
  border-radius: 50%;
  margin-right: 15px;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  padding-top: 15px;
  padding-bottom: 7px;
}

.profileName {
  color: #fff;

  /* font-family: 'PP Telegraf'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
}

.profileEmail {
  color: #fff;
  /* font-family: 'PP Telegraf'; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  opacity: 0.5;
}

.settingButtonContainer {
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;
  border-radius: 15px;
}

.settingsButton {
  background: none;
  border: none;
  background: #121410;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  display: flex;
  align-items: center;
  width: 145px;
  height: 40px;
  color: #fff;

  /* font-family: 'Exo 2'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  column-gap: 9px;
}

/* .settingsButton img {
} */

.settingsButton:hover {
  /* color: #a0ff00;  */
  filter: brightness(1.5);
  cursor: pointer;
}

@media (max-width: 1100px) {
  .mainContainer {
    padding: 0px 0px 30px 0px;
  }
}

@media (max-width: 580px) {
  .welcomeMessage {
    font-size: 26px;
    line-height: 100%;
    text-align: left;
  }
}


@media (max-height: 820px) {

 .welcomeMessage{
  font-size: 25px;
line-height: 100%;
 }
 .mainContainer{
  padding: 20px 0px;
 }
}