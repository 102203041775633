.buttonWallet {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #22251f;
  color: #caff00;
  padding: 12px;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  column-gap: 10px;
  width: max-content;
}

.buttonLogOut {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  /* width: 90px;
    height: 40px; */
  padding: 12px;
  border-radius: 15px;
  background: #22251f;
  color: rgb(255, 255, 255, 0.5);
  /* opacity: 0.5; */
  column-gap: 10px;
  /* font-family: 'Exo 2'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}

.buttonWallet:hover,
.buttonLogOut:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

@media (max-width: 1850px) {
  .buttonWallet {
    width: 100%;
    max-width: 120px;
  }
  .buttonLogOut {
    width: 100%;

    max-width: 120px;
  }
}
