.mainContainer {
  background-color: #0a0c0a;
  color: white;
  /* padding: 20px; */
  /* margin-left: 65px; */
  /* margin-top: 65px; */

  font-family: 'Exo 2';
  display: flex;
  justify-content: center;
}
.subContainer {
  max-width: 34.896vw;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}

.overviewHeader {
  display: flex;
  justify-content: space-between;
}

.headingContainer {
  display: flex;

  /* justify-content: center; */

  align-items: center;
}

.heading {
  color: #fff;

  font-style: normal;
  font-weight: 400;

  font-size: 30px;
  text-align: left;
  line-height: 100%; /* 100% */
}

.headingContainer img {
  margin-top: 6px;
  margin-left: 20px;
  width: 21px;
  height: 21px;
}
.dropdownsContainer {
  display: flex;
  gap: 10px;
}
.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;

  /* border-radius: 15px; */
  /* border: 1px solid rgba(53, 58, 51, 0); */
  /* background: #121410; */
}

.dropdownContainer .dropdown {
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 0px 27px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  /* width: 100%; */
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
  row-gap: 30px;
  padding-top: 39px;
}

.card {
  position: relative;
  /* overflow: hidden; */
  display: flex;
  /* padding: 20px; */
  /* box-sizing: border-box; */
  width: 100%;
  max-width: 11.25vw;
  height: 115px;
  justify-content: space-between;
  background-color: #1b1f19;
  border-radius: 20px;
  transition: transform 0.01s ease;
}

.card:hover {
  filter: brightness(1.1);
  transform: scale(1.02);
  cursor: pointer;
}

.cardContentContainer {
  width: 100%;
  /* height: 100%; */
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  padding: 20px 12px 20px 20px;
  /* align-items: center; */

  /* flex-direction: column; */
}
/* .card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 2px;
  background: linear-gradient(to right, #F03E3E 0%, #F03E3E 32%, #FFB5B5 50%, #F03E3E 69%, #F03E3E 100%);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
} */

.gradient {
  position: absolute;
  top: 0;
  /* left:50%; */
  z-index: 1;
  left: 0;

  width: 100%;
  height: 100%;
  /* background: radial-gradient(circle, #caff00 0%, #caff00 50%, #faffe9 100%, #caff00 100%); */
  /* filter: blur(50px); */
  /* background: radial-gradient(circle, #CAFF00 0%, #CAFF00 50%, #FAFFE9 100%, #CAFF00 100%); */
}

.gradient img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.cardDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
  row-gap: 25px;
}

.valueGreen {
  background: linear-gradient(to right, #caff00 0%, #f6ffd1 90%);
  -webkit-background-clip: text;
  /* font-family: 'Exo 2'; */

  /* font-size: 24px; */
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  background-clip: text;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 100% */
}

.valueRed {
  background: linear-gradient(to right, #ff0000 0%, #ffcccc 90%);
  -webkit-background-clip: text;
  /* font-family: 'Exo 2'; */

  /* font-size: 24px; */
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  background-clip: text;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 100% */
}

.valueGrey {
  background: grey;
  -webkit-background-clip: text;
  /* font-family: 'Exo 2'; */

  /* font-size: 24px; */
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  background-clip: text;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 100% */
}

.collectionName {
  color: #fff;

  /* font-family: 'Exo 2'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}

.graph {
  display: flex;
  z-index: 1;
  /* width: 84px;
  height: 57px; */
}
.graph img {
  /* width: 100%;
  height: 100%; */
}

.infoIconWrapper {
  position: relative;
  display: flex;
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.infoIconWrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1750px) {
  .subContainer {
    max-width: 35.286vw;
    width: 100%;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
  }

  .gradient {
    height: fit-content;
  }

  .card {
    position: relative;
    /* overflow: hidden; */
    display: flex;
    /* padding: 20px; */
    /* box-sizing: border-box; */
    width: 100%;
    /* max-width: 10.25vw; */
    height: 115px;
    justify-content: space-between;
    background-color: #1b1f19;
    border-radius: 20px;
  }

  .cardContentContainer {
    width: 100%;
    /* height: 100%; */
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    padding: 20px 12px 20px 20px;
    /* align-items: center; */

    /* flex-direction: column; */
  }

  .valueGreen {
    font-size: 22px;
  }

  .valueRed {
    font-size: 22px;
  }

  .valueGrey {
    font-size: 22px;
  }

  .collectionName {
    color: #fff;

    /* font-family: 'Exo 2'; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 100% */
  }

  .graph {
    display: flex;
    z-index: 1;
    width: 60px;
    /* width: 84px;
  height: 57px; */
  }
  .graph img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1640px) {
  .dropdownContainer .dropdown {
    display: flex;
    align-items: center;
    background-color: #121410;
    color: #fff;
    padding: 0px 27px;
    cursor: pointer;
    border-radius: 13px;
    position: relative;
    z-index: 3;
  }

  .headingContainer img {
    margin-top: 0px;
    margin-left: 20px;
    width: 21px;
    height: 21px;
  }

  .heading {
    color: #fff;

    /* font-family: 'Exo 2'; */
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 20px;
    padding-top: 30px;
  }

  .card {
    height: 110px;
  }
}

@media (max-width: 1540px) {
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 15px;
    padding-top: 30px;
  }
}

@media (max-width: 1440px) {
  .subContainer {
    max-width: 32.286vw;
  }
  .card {
    max-width: 10.25vw;
  }
  .graph {
    width: 45px;
  }
}

@media (max-width: 1415px) {
  .subContainer {
    max-width: 100%;
  }

  .card {
    max-width: 170px !important;
    width: 170px;
  }

  .graph {
    width: 45px;
  }
}

@media (max-width: 620px) {
  .heading {
    font-size: 22px;
    width: min-content;
  }
}

@media (max-width: 550px) {
  .dropdownContainer {
    /* margin-left: 20px; */
  }
}

@media (max-width: 510px) {
  .cardContainer {
    flex-direction: column;
    gap: 20px;
  }

  .card {
    min-width: 100% !important;
  }
  .gradient img {
    max-height: 100px;
  }
}

@media (min-width: 2280px) {
  .card {
    max-width: 11.25vw;
  }

  .mainContainer {
    max-width: 34.896vw;
    width: 100%;
  }
  .subContainer {
    width: 100%;
  }
}

@media (min-width: 2460px) {
  .subContainer {
    width: 100%;
  }
  .card {
    max-width: 11.25vw;
  }
}

@media (min-width: 2720px) {
  .card {
    max-width: 11.25vw;
    /* max-width: 312px; */

    /* max-width: 275px; */
  }
}

@media (max-height: 1100px) {

  .card{

      height: 90px;
      max-width: 10vw;

  }

  .cardContentContainer{

    padding: 15px;
  }
  .gradient {
    height: 100%;
  }

  .cardDetails{
    row-gap: 10px;
  }
  .valueGreen{
    font-size: 20px;
  }
  .valueRed{
    font-size:20px;
  }
  .valueGrey{
    font-size: 16px;
  }
  .collectionName{
    font-size: 12px;
  }
  /* .dropdownContainer .dropdown{
    padding: 10px 15px;
  } */
  .cardContainer{
    padding-top: 20px;
    row-gap: 10px;
    column-gap: 10px;
  }
  .card{
    border-radius: 12px;
  }
.gradient img{
  border-radius: 12px;
}
.graph {
  height: 30px;
}
}

@media (max-height: 820px) {

  .card{

      height: 60px;
      max-width: 9vw;

  }

  .cardContentContainer{

    padding: 10px;
  }
  .gradient {
    height: 100%;
  }

  .cardDetails{
    row-gap: 5px;
  }
  .valueGreen{
    font-size: 16px;
  }
  .valueRed{
    font-size: 16px;
  }
  .valueGrey{
    font-size: 16px;
  }
  .collectionName{
    font-size: 12px;
  }
  .dropdownContainer .dropdown{
    padding: 10px 15px;
  }
  .cardContainer{
    padding-top: 20px;
    row-gap: 10px;
    column-gap: 10px;
  }
  .card{
    border-radius: 12px;
  }
.gradient img{
  border-radius: 12px;
}
.graph {
  height: 30px;
}
}
