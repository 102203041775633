.infoIconWrapper {
  position: relative;
  display: flex;
  cursor: pointer;
  z-index: 1;
}

.tooltip {
  
  text-align: left !important;
  width: 320px;
  background-color: #1b1f19;
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, visibility 0.3s;
}

.infoButtonDiv {
  /* margin-top: 10px; */
  text-align: right;
}

.infoButton {
  color: #fff;
  text-decoration: underline;
  display: flex;
}

.infoButton:hover {
  text-decoration: underline;
}


