.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.2); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.loader {
  --s: 40px; /* control the size */
  /* --c: linear-gradient(135deg,#00A8C6,#8FBE00);  */
  --c: linear-gradient(0deg, rgba(202, 255, 0, 0.00) -0.23%, #CAFF00 100.86%);

  display: grid;
  place-items: center;
  place-content: center;
  margin: 0 calc(var(--s)/2);
}
.loader:before,
.loader:after{
  content: "";
  grid-area: 1/1;
}
.loader,
.loader:before,
.loader:after{
  height: var(--s);
  width: calc(var(--s)/5);
  border-radius: var(--s);
  background: var(--c) calc(50% + var(--_i,0)*50%)/var(--s) var(--s);
  transform: translate(calc(var(--_i,0s)*200%));
  animation: l .6s infinite alternate calc(var(--_i,0)*.3s) both;
}
.loader:before {--_i: -1}
.loader:after  {--_i:  1}

@keyframes l {
  from {height: calc(var(--s)/2)}
}




.typingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dim-green);
  padding: 12px 8px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  width: 50px; 
}

.typingIndicator span {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background: #6c6c6c; /* Dark grey dots */
  border-radius: 50%;
  display: inline-block;
  animation: typingAnimation 1.5s infinite ease-in-out;
}

.typingIndicator span:nth-child(1) {
  animation-delay: 0s;
}

.typingIndicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typingIndicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typingAnimation {
  0% { opacity: 0.3; transform: translateY(0px); }
  50% { opacity: 1; transform: translateY(-4px); }
  100% { opacity: 0.3; transform: translateY(0px); }
}