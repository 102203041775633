.custom-toast-success .Toastify__toast-icon svg {
    fill: #caff00 !important; 
  }
  
  .custom-toast-success .Toastify__progress-bar {
    background-color: #caff00 !important; 
  }
  
  
  .custom-toast.custom-toast-error .Toastify__toast-icon svg {
    fill: #f44336 !important; 
  }
  
  .custom-toast.custom-toast-error .Toastify__progress-bar {
    background-color: #f44336 !important; 
  }
  
  
  .custom-toast-success {
    background-color: #22251f !important;
    color: #caff00 !important;
  }
  
  .custom-toast-error {
    background-color: #22251f !important;
    color: #f44336 !important;
  }