.mdDocs {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.6;
  color: #ffffff;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  max-width: 1000px;
  background-color: transparent; /* Transparent background */
}

/* Tab Styles */
.tabContainer {
  width: 100%;
  display: flex;
  border: 0.5px solid grey;
  display: flex;
  justify-content: space-between;
  border-radius: 25px;
  margin-bottom: 50px;
}

.tab {
  width: 100%;
  background-color: transparent; /* Transparent background for tabs */
  color: #ffffff; /* White text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding for tabs */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 18px; /* Font size for tabs */
  font-weight: bold;
  transition: background-color 0.3s ease; /* Smooth transition */
  border-radius: 25px;
  margin: 5px;
}
/* 
.tab:not(.activeTab):hover {
  background-color: rgba(220, 220, 220, 0.1);
  background-color: rgba(105,105,105 , 0.2);
} */

.activeTab {
  background-color: #a0ff00; /* Light green background for active tab */
  color: black;
}

/* Card Styles */
.card {
  background-color: transparent; /* Transparent background for cards */
  border-radius: 8px; /* Rounded corners for cards */
  margin-bottom: 20px; /* Space between cards */
  padding: 15px; /* Padding inside cards */
  box-shadow: none; /* No shadow for cards */
}

.cardContent {
  display: flex; /* Flexbox for image and text layout */
  align-items: center; /* Center vertically */
}

.cardContent img {
  max-width: 45%; /* Image width */
  margin-right: 20px; /* Space between image and text */
  border-radius: 8px; /* Rounded corners for images */
}

.cardContent p {
  flex: 1; /* Allow text to take remaining space */
  color: #d0d0d0; /* Text color */
}

/* Markdown Content */
.markdownContent {
  color: #d0d0d0; /* Light text for readability */
}

.mdDocs p {
  margin-bottom: 16px;
}

.mdDocs img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: none;
}
.mdDocs hr {
  margin: 20px 0px;
  opacity: 0.5;
}

/* Error message styling */
.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  height: 100%; /* Full viewport height */
  width: 100%;
}

.errorMessage {
  color: #ffffff;
  font-size: 32px;
  text-align: center;
}

@media (max-width: 900px) {
  .tab {
    font-size: 15px;
  }
}

@media (max-width: 520px) {
  .tabContainer {
    flex-wrap: wrap;
  }
}
