:root {
  --dark-bg: #0a0a0a;
  --darker-bg: #050505;
  --accent-green: #1aff00;
  --dim-green: rgba(26, 255, 0, 0.1);
  --message-bg: #1a1a1a;
  --border-color: rgba(26, 255, 0, 0.2);
}

/* Fixed layout with 2 columns */
.LLM-view .app-container {
  display: grid;
  grid-template-columns: 50% 48%;
  gap: 2%;
  min-height: 70vh;
  padding: 20px;
  box-sizing: border-box;
}

.LLM-view .chat-container {
  height: 100%;
}

.LLM-view .data-visualization {
  display: grid;
  grid-template-rows: 60% 37%;
  gap: 3%;
  height: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.LLM-view .chat-interface {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(10, 10, 10, 0.8);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  padding: 20px;
}
.LLM-view .chat-messages-container {
  /* border: 1px solid var(--border-color); */
  
  border-radius: 8px;
  margin-bottom: 20px;
  flex: 1 1;
}

.LLM-view .chat-messages {
  flex: 1;

  padding: 10px;
  background: var(--darker-bg);
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}

/* Updated message styling with icons */
.LLM-view .message {
  padding: 12px;
  border-radius: 8px;
  max-width: 70%;
  border: 1px solid var(--border-color);
  align-content: flex-start;
  text-align: left;
  /* display: flex;
  align-items: flex-start; */
  /* gap: 10px; */
}

.LLM-view .message-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.LLM-view .user-icon {
  background-color: var(--dim-green);
  color: var(--accent-green);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.LLM-view .assistant-icon {
  background-color: var(--message-bg);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.LLM-view .message-content {
  /* flex: 1; */
}

.LLM-view .message.user {
  background: var(--dim-green);
  color: white;
  margin-left: auto;
  border-radius: 15px 15px 0px 15px;
}

.LLM-view .message.assistant {
  background: var(--message-bg);
  color: #ffffff;
  margin-right: auto;
}

.LLM-view .message.system {
  background: rgba(255, 0, 0, 0.1);
  color: #fff344;
  /* margin: 10px 0px; */
  border-radius: 15px 15px 15px 0px;
  text-align: center;
  border-color: rgba(255, 0, 0, 0.2);
}

.LLM-view .message.clickable {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.LLM-view .message.clickable:hover {
  background-color: rgba(26, 255, 0, 0.1);
}

.LLM-view .message.selected {
  border: 1px solid var(--accent-green);
  box-shadow: 0 0 5px rgba(26, 255, 0, 0.2);
}

.LLM-view .chat-input {
  flex-shrink: 0;
  display: flex;
  gap: 10px;
  background: var(--darker-bg);
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.LLM-view .chat-input input {
  flex-grow: 1;
  padding: 12px;
  background: var(--message-bg);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: white;
  font-size: 14px;
}

.LLM-view .chat-input input:focus {
  outline: none;
  border-color: var(--accent-green);
}

.LLM-view .chat-input input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.LLM-view .chat-input button {
  padding: 12px 25px;
  background: var(--dim-green);
  color: var(--accent-green);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease;
}

.LLM-view .chat-input button:hover:not(:disabled) {
  background: var(--accent-green);
  color: var(--darker-bg);
}

.LLM-view .chat-input button:disabled {
  background: var(--message-bg);
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}


.LLM-view .plot-container {
  background: rgba(10, 10, 10, 0.8);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  backdrop-filter: blur(10px);
  padding: 15px;
}

.table-container {
  background: rgba(10, 10, 10, 0.8);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  backdrop-filter: blur(10px);
  padding: 15px;
  overflow: auto;
}

.LLM-view .plot-wrapper {
  height: 100%;
  width: 100%;
}
.LLM-view .js-plotly-plot .plotly,
.LLM-view .js-plotly-plot .plotly div {
  direction: ltr;
  font-family: 'Open Sans', verdana, arial, sans-serif;
  margin: 0px;
  padding: 0px;
  /* width: 100%; */
  height: 100%;
  /* Add any other custom styles you want */
}
.LLM-view .table-wrapper {
  height: 100%;
  width: 100%;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  color: white;
  font-size: 0.9rem;
}

.data-table th,
.data-table td {
  padding: 8px;
  border: 1px solid var(--border-color);
  text-align: right;
}

.data-table th {
  background: var(--dim-green);
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.data-table .asset-name {
  text-align: left;
  font-weight: bold;
  color: white;
}

.data-table .price {
  font-family: monospace;
  text-align: right;
}

.data-table .return {
  font-weight: bold;
  text-align: right;
}

.data-table .return.positive {
  color: var(--accent-green);
}

.data-table .return.negative {
  color: #ff4444;
}

.data-table .z-score {
  font-family: monospace;
  text-align: right;
}

.data-table .z-score.high {
  color: var(--accent-green);
  font-weight: bold;
}

.data-table .z-score.low {
  color: #ff4444;
  font-weight: bold;
}

.data-table .z-score.medium {
  color: #ff9900;
  font-weight: bold;
}

.data-table .timestamp {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

.data-table tbody tr:hover {
  background: rgba(26, 255, 0, 0.05);
}

.data-table .highlight-row {
  background: rgba(251, 255, 0, 0.1);
}

.chat-messages-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.chat-messages-container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
/* Custom scrollbar */
/* ::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--darker-bg);
}

::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dim-green);
} */

.default-questions {
  background: var(--darker-bg);
  border-radius: 8px;
  transition: visibility 0.3s ease, opacity 0.3s ease;
}

.default-questions.hidden {
  visibility: hidden;
  opacity: 0.3;
  pointer-events: none;
}

.default-questions-label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: left;
}

.default-questions-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: 1;
  transition: opacity 0.3s ease;
  padding: 0px 10px;
}

.default-questions-visible {
  height: auto;
  opacity: 1;
  transition: height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
  text-align: left;
  gap:10px;
  display: flex;
  flex-wrap: wrap;
}

.default-questions-hidden {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.5s ease, opacity 0.5s ease;
  margin: 0;
}

.default-questions-subcontainer {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  gap:10px;
}

.default-question-button {
  background: var(--dim-green);
  color: var(--accent-green);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: normal;
  text-align: left;
  /* margin-bottom: 8px; */
  width: fit-content;
}

.default-question-button:hover {
  background: var(--accent-green);
  color: var(--darker-bg);
}

.default-question-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.typing-cursor {
  display: inline-block;
  width: 2px;
  animation: blink 1s step-end infinite;
  margin-left: 2px;
  color: var(--accent-green);
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.symbol-highlight {
  display: inline-block;
  background: rgba(255, 244, 68, 0.1);
  border: 1px solid rgba(255, 244, 68, 0.3);
  border-radius: 4px;
  padding: 0 4px;
  margin: 0 2px;
  color: #fff344;
  font-weight: bold;
  box-shadow: 0 0 8px rgba(255, 244, 68, 0.1);
  text-decoration: none;
  transition: all 0.2s ease;
}

.symbol-highlight:hover {
  background: rgba(255, 244, 68, 0.2);
  box-shadow: 0 0 12px rgba(255, 244, 68, 0.2);
  text-decoration: none;
  color: #fff344;
}

.price-highlight {
  color: #00ffff;
  font-family: monospace;
  font-weight: 500;
  padding: 0 2px;
}

.zscore-highlight {
  font-family: monospace;
  font-weight: bold;
  padding: 0 2px;
}

.zscore-high {
  color: #1aff00;
}

.zscore-low {
  color: #ff4444;
}

.zscore-neutral {
  color: #ff9900;
}

.percent-highlight {
  font-family: monospace;
  font-weight: bold;
  padding: 0 2px;
}

.percent-positive {
  color: #1aff00;
}

.percent-negative {
  color: #ff4444;
}

.message-group {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin: 10px 0px;
}

@media (max-width: 980px) {
  .LLM-view .app-container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 40% 30% 30%; */
    gap: 15px;
    padding: 15px;
    overflow-y: auto;
    height: auto;
    min-height: 100vh;
  }
  .LLM-view .data-visualization {
    grid-template-rows: 1fr 1fr;
    gap: 15px;
    height: auto;
  }
}
.rounded-table table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
}

.rounded-table thead th:first-child {
  border-top-left-radius: 8px;
}

.rounded-table thead th:last-child {
  border-top-right-radius: 8px;
}

.rounded-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.rounded-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
