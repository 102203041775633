/* Common styles */
.containerTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emptyState {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  /* height: 100px; */
  color: #9a9a9a;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: rgba(255, 255, 255, 0.05);
}

.refreshIcon {
  width: 15px;
  height: 15px;
  color: var(--accent-green);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.refreshIcon:hover {
  transform: rotate(180deg);
}

/* Proposals Containers */
.proposalsContainer {
  position: relative;
  background-color: rgba(99, 99, 99, 0.1);
  border-radius: 12px;
  padding: 5px 15px;
  height: 100%;
  max-height: 350px;
  display: flex;
  flex-direction: column;
}

.proposalsList {
  /* padding: 5px; */
  overflow-y: auto;
}

.proposalItem {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: left;
}

.proposalItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.proposalItem.selected {
  background: linear-gradient(to right, var(--dim-green), var(--dim-green));
  border-left: 3px solid var(--accent-green);
}

.proposalTitle {
  font-weight: 500;
  margin-bottom: 6px;
  color: #ffffff;
}

.votedChip {
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: rgba(255, 184, 0, 0.2);
  color: #ffb800;
  font-weight: 600;
}

.proposalTime {
  font-size: 12px;
  color: #eb5757;
}

.proposalTimeRemaining {
  font-size: 12px;
  color: var(--accent-green);
}

.proposalTimeRemainingClosed {
  font-size: 12px;
  color: #eb5757;
}

/* Selected Proposal */
.selectedProposalContainer {
  position: relative;
  background-color: rgba(99, 99, 99, 0.1);
  border-radius: 12px;
  padding: 5px 15px;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.proposalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.proposalStatus {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
}

.open {
  background-color: var(--dim-green);
  color: var(--accent-green);
}

.closed {
  background-color: rgba(235, 87, 87, 0.2);
  color: #eb5757;
}

.proposalDescription {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  line-height: 1.5;
  color: #e0e0e0;
  text-align: left;
  white-space: pre-line;
}

.proposalTimeline {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 20px;
}

.timelineItem {
  color: #9a9a9a;
  font-size: 14px;
}

.timelineItem span {
  color: #e0e0e0;
  font-weight: 500;
}

.optionsList {
  margin-top: 20px;
}

.optionsList h4 {
  margin-bottom: 15px;
  color: #ffffff;
}

.optionItem {
  margin-bottom: 15px;
}

.optionDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.optionName {
  color: #e0e0e0;
}

.optionVotes {
  color: #9a9a9a;
  font-size: 14px;
}

.progressBarContainer {
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background-color: var(--accent-green);
  border-radius: 4px;
  transition: width 0.3s ease;
}

/* Vote Component */
.voteContainer {
  position: relative;
  background-color: rgba(99, 99, 99, 0.1);
  border-radius: 12px;
  padding: 5px 15px;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.alreadyVoted {
  background-color: rgba(255, 184, 0, 0.1);
  border-left: 3px solid #ffb800;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 0 8px 8px 0;
  color: #ffb800;
}

.votingClosed {
  background-color: rgba(235, 87, 87, 0.1);
  border-left: 3px solid #eb5757;
  padding: 10px 15px;
  margin: 20px 0;
  border-radius: 0 8px 8px 0;
  color: #eb5757;
  text-align: left;
}

.optionsRadioGroup {
  margin-bottom: 20px;
  width: 100%;
}

.optionRadio {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: left;
}

.optionRadio:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.optionRadio.selected {
  background: linear-gradient(to right, var(--dim-green), var(--dim-green));
}

.optionRadio.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.radioButton {
  width: 15px;
  height: 15px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.optionRadio.selected .radioButton {
  border-color: var(--accent-green);
}

.radioInner {
  width: 10px;
  height: 10px;
  background-color: var(--accent-green);
  border-radius: 50%;
  display: none;
}

.optionRadio.selected .radioInner {
  display: block;
}

.radioLabel {
  color: #e0e0e0;
  text-align: left;
  width: 100%;
  word-break: break-word;
}

.refreshButton {
  border-radius: 15px;
  background: #22251f;
  color: #caff00;
  padding: 5px 7px 7px 7px;
  font-family: "Exo 2";
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.voteButton {
  border-radius: 15px;
  background: #22251f;
  color: #caff00;
  padding: 12px;
  font-family: "Exo 2";
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.voteButton:hover,
.refreshButton:hover {
  background: #2a2e27;
}

.voteButton.disabled,
.refreshButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.buttonGroup {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.cancelButton {
  border-radius: 15px;
  background: rgba(235, 87, 87, 0.1);
  color: #eb5757;
  padding: 12px;
  font-family: "Exo 2";
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 1;
}

.cancelButton:hover {
  background: rgba(235, 87, 87, 0.2);
}

.voteButton {
  flex: 2;
}

/* Access Restriction */
.accessRestrictionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.accessRestrictionModal {
  background-color: rgba(99, 99, 99, 0.1);
  border-radius: 12px;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.logo {
  width: 80px;
  margin-bottom: 20px;
}

.accessRestrictionModal h2 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 24px;
}

.accessRestrictionModal p {
  color: #9a9a9a;
  margin-bottom: 25px;
  line-height: 1.5;
}

.highlight {
  color: #00b894;
  font-weight: 600;
}

.actions {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.connectWalletButton,
.getTokensButton {
  flex: 1;
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  min-width: 140px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s ease;
}

.connectWalletButton {
  background-color: #00b894;
  border: none;
  color: #ffffff;
}

.connectWalletButton:hover {
  background-color: #00a080;
}

.getTokensButton {
  background-color: transparent;
  border: 2px solid #00b894;
  color: #00b894;
}

.getTokensButton:hover {
  background-color: rgba(0, 184, 148, 0.1);
}

/* DAO page layout */
.daoPageGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 15px;
  height: 100%;
}

.selectedProposalGrid {
  grid-column: 1;
  grid-row: 1 / span 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.voteComponentGrid {
  grid-column: 2;
  grid-row: 1 / span 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.activeProposalsGrid {
  grid-column: 1;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.closedProposalsGrid {
  grid-column: 2;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow-y: auto; */
}

@media (max-width: 960px) {
  .daoPageGrid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    max-height: none;
  }

  .selectedProposalGrid,
  .voteComponentGrid,
  .activeProposalsGrid,
  .closedProposalsGrid {
    grid-column: 1;
  }

  .selectedProposalGrid {
    grid-row: 1;
  }

  .voteComponentGrid {
    grid-row: 2;
  }

  .activeProposalsGrid {
    grid-row: 3;
  }

  .closedProposalsGrid {
    grid-row: 4;
  }
}
