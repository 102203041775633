.modalOverlay {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  width: 100%;
  height: 100%;
}

.modalContent {
  position: relative;
  padding: 70px 100px;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  background: #0a0c0a;
  border-radius: 30px;
  min-width: 400px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  line-height: 1;
}

.closeButton:hover {
  opacity: 0.8;
}

.content {
  margin: 20px 0;
  color: white;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.title {
  color: white;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.accessButton {
  border-radius: 15px;
  background: #22251f;
  color: #caff00;
  padding: 12px;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
  column-gap: 10px;
  cursor: pointer;
}

.accessButton:hover {
  filter: brightness(1.5);
}
