.mainContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  /* height: max-content; */
  font-family: "Exo 2";
  min-height: 100vh;
  height: 100%;

  /* width: 310px; */
  /* overflow: scroll; */

  /* Add other styles as needed */
}

.subContainer {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 32px 26px;
  margin: 0px 10px;
  height: 100%;
  padding-bottom: 0px;

  overflow-y: scroll;
  overflow-x: hidden;
}

.logoText {
}
.logo {
  display: flex;
  margin-left: 10px;
  cursor: pointer;
  /* padding-top: 20px; */
}

.menu {
  display: flex;
  flex-direction: column;
  row-gap: 43px;
  padding-top: 81px;
}

@media (max-width: 1800px) {
  .menu {
    row-gap: 30px;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  /* padding: 10px; */
  column-gap: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.iconContainer {
  height: 39.5px;
  width: 39.5px;
}

/* .iconContainer img {
  height: 100%;
  width: 100%;
} */
.labelContainer {
  border-radius: 15px;
  background: transparent;
  padding: 1px;
}

.label {
  display: flex;
  border-radius: 15px;
  background-color: transparent;

  align-items: center;
  color: #fff;
  width: 128px;
  height: 40px;
  padding-left: 17px;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
}

/* Selected item styles */
.selected .labelContainer {
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
}

.selected .label {
  background-color: #121410;
}
.selected img {
  filter: brightness(1.5);
}

.bottom {
  /* position: absolute; */
  position: relative;
  max-width: 250px;
  bottom: 0px;
  border-radius: 12.944px;
  margin: 30px 0px 60px 0px;

  padding: 1px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
}

.bottomInner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 20px;
  border-radius: 12.944px;
  column-gap: 8px;
  background: #121410;
}
.buttonWallet {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;

  background: #22251f;
  color: #caff00;
  padding: 12px;

  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  column-gap: 10px;
}

.buttonLogOut {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 90px;
  height: 40px; */
  padding: 12px;
  border-radius: 15px;

  background: #22251f;
  color: rgb(255, 255, 255, 0.5);
  /* opacity: 0.5; */
  column-gap: 10px;
  font-family: "Exo 2";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}

.buttonWallet:hover,
.buttonLogOut:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

.menuItemDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.comingSoon {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #22251f;
  color: #caff00;
  padding: 5px;
  font-family: "Exo 2";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  column-gap: 10px;
}

@media (max-width: 1850px) {
  /* .subContainer {
    padding: 32px 36px;
  } */
  .bottom {
    /* row-gap: 10px; */
    border-radius: 12.944px;

    /* padding: 18px 30px; */
  }

  .bottomInner {
    display: flex;
    flex-direction: column;
    padding: 18px 20px;
    border-radius: 12.944px;
    row-gap: 8px;
    background: #121410;
  }
  .buttonWallet {
    width: 100%;
  }

  .buttonLogOut {
    width: 100%;
  }
}

@media (max-width: 1640px) {
  .menu {
    row-gap: 30px;
  }
}

@media (max-width: 1380px) {
  .label {
    width: 100px;
  }

  .menuItem {
    column-gap: 10px;
  }
}

@media (max-width: 1100px) {
  .logo {
    display: none;
  }
  .mainContainer {
    height: 100%;
    overflow-y: scroll;
    justify-content: flex-start;
  }

  .subContainer {
    /* padding: 0px 32px; */
    /* padding-top: 0px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-bottom: 100px; */
    padding-bottom: 60px;
  }
  .bottom {
    position: unset;
    margin-top: 30px;
  }
}

@media (min-width: 1920px) {
  .subContainer {
    align-items: center;
  }
}
