.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background-color: #0a0c0a;
  border-radius: 8px;
  width: 100%;
  font-family: 'Exo 2';
  /* max-width: 45%; */
}

.subContainer {
  width: 100%;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.headerText {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: #fff;
}

.headerContainer img {
  height: 21px;
  width: 21px;
  margin-left: 20px;
}

.overviewHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mainDropdownContainer {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: flex-end;
  row-gap: 20px;
}

.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;
}

.dropdownContainer .dropdown {
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

.chartContainer {
  position: relative;
  height: 400px;
}

.chartBG {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
}

.chartBG img {
  width: 100%;
  height: 100%;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
}

@media (max-width: 1640px) {
  .headerText {
    font-size: 28px;
  }
}

@media (max-width: 620px) {
  .headerText {
    font-size: 22px;
    text-align: left;
    line-height: 24px;
  }
}

@media (max-width: 580px) {
  .headerText {
    font-size: 22px;
    text-align: left;
    line-height: 24px;
    width: min-content;
  }

  .dropdownContainer {
    height: fit-content;
  }
  .mainDropdownContainer {
    align-items: center;
  }

  .chartBG img {
    border-radius: 25px;
  }
}

@media (max-width: 520px) {
  .chartContainer {
    height: 430px;
  }
}
