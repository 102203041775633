.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0c0a;
}

.mainContainer {
  background: url("../public/images/bgDashboard.svg");
  /* background-color: #050a02; */
  /* min-width: 1920px; */

  background-position: top center;
  background-repeat: no-repeat;

  /* background-color: black; */
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  /* background-position: center center;   */
}

.loading-overlay {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(30px);
  pointer-events: none;
  z-index: 10;
  top: 0;
  left: 0;
  border-radius: 50px;
  border: 0.4px solid #353a33;
}

@media (max-width: 780px) {
  .loading-overlay {
    border-radius: 25px;
  }
}
