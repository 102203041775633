.dropdownContainer {
    position: relative;
    display: flex;
    border-radius: 15px;
    background: linear-gradient(to right, #353a33 0%, #121410 100%);
    padding: 1px;
    min-height: 40px;
    
  
    /* border-radius: 15px; */
    /* border: 1px solid rgba(53, 58, 51, 0); */
    /* background: #121410; */
  }
  
  .dropdownContainer .dropdown {
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    background-color: #121410;
    color: #fff;
    padding: 0px 27px;
    cursor: pointer;
    border-radius: 13px;
    position: relative;
    z-index: 3;
  }
  
.selectedOption {
    display: flex;
    align-items: center;
  }
  
  .selectedOption img {
    margin-right: 5px;
  }
  
  .arrow {
    display: flex;
    margin-left: 10px;
  }
  
  .invertedArrow {
    display: flex;
    transform: rotate(180deg);
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #121410;
    color: #fff;
    border-radius: 5px;
    margin-top: 5px;
    z-index: 3;
  }
  
  .dropdownItem {
    /* width: 100%; */
    padding: 10px 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdownItem img {
    margin-right: 5px;
  }
  
  .dropdownItem:hover {
    background-color: #444;
  }
  