.mainContainer {
  display: flex;
  position: relative;
  font-family: 'Exo 2';
  max-width: 50%;
}

.headerContainer {
  position: absolute;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
  color: #fff;
  width: 100%;
}

.headerTextContainer {
  display: flex;
  align-items: center;
}

.headerText {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.headerContainer img {
  height: 21px;
  width: 21px;
  margin-left: 20px;
  /* margin-bottom: 4px; */
}

.dropdownContainer {
  position: relative;
  display: flex;
  border-radius: 15px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;

  /* border-radius: 15px; */
  /* border: 1px solid rgba(53, 58, 51, 0); */
  /* background: #121410; */
}

.dropdownContainer .dropdown {
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  background-color: #121410;
  color: #fff;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 13px;
  position: relative;
  z-index: 3;
}

.selectedOption {
  display: flex;
  align-items: center;
}

.selectedOption img {
  margin-right: 5px;
}

.arrow {
  display: flex;
  margin-left: 10px;
}
.arrow img {
  height: auto !important;
  width: auto !important;
  margin-left: 0px !important;
}

.invertedArrow {
  display: flex;
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #121410;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 3;
}

.dropdownItem {
  /* width: 100%; */
  padding: 10px 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem img {
  margin-right: 5px;
}

.dropdownItem:hover {
  background-color: #444;
}

@media (max-width: 1640px) {
  .headerText {
    font-size: 28px;
  }
}

@media (max-width: 1415px) {
  .mainContainer {
    width: 100%;
    max-width: 100%;
  }
  .plotContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .mainContainer {
    height: 100%;
  }

}

@media (max-width: 620px) {
  .headerText {
    font-size: 22px;
  }
}
