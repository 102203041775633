@media (min-width: 1930px) {
  .border {
    /* height: 100%; */
  }

  .rightBottomContainer {
    /* position: relative; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly !important;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;

  justify-content: center;
  /* border: 1px solid transparent; */
  /* margin: 150px; */

  width: 100%; /* width: 100%; */
}

.subContainer {
  display: flex;
  /* height: 100vh; */
  /* justify-content: center; */
  /* align-items: center; */
}

.leftContainer {
  width: 19%;
  /* height: 800px; */
  /* border: 1px solid red; */
  z-index: 999;
  height: 100vh;

  transition: transform 0.3s ease-in-out;
}

.hamburger {
  display: none;
  position: fixed;
  top: 40px;
  left: 20px;
  z-index: 1001;
  cursor: pointer;
}
.rightContainer {
  margin-right: 37px;
  /* margin-top: 115px; */
  margin-bottom: 40px;
  transition: width 0.3s ease-in-out;
  /* width: fit-content; */
  width: 100%;

  /* padding */
}
.logo {
  display: none;
}

.border {
  /* border: 1px solid black; */
  border-radius: 20px;
}

.rightTopContainer {
  width: 100%;
  /* height: 115px; */
  /* border: 1px solid blue; */
}

.gradient {
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;
  height: 100%;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* height: 800px; */
  /* border: 1px solid blue; */
  border-radius: 50px;
}
.rightBottomContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* width: 100%; */
  height: calc(100vh - 185px);
  background: #0a0c0a;
  border-radius: 50px;
  row-gap: 60px;
  /* column-gap: 135px; */
  box-sizing: border-box;

  padding-top: 30px;
  padding-right: 25px;
  padding-left: 30px;
  padding-bottom: 30px;
}

.fullWidthRightBottomContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  /* width: 100%; */
  min-height: 100vh !important;
  background: #0a0c0a;
  border-radius: 0px;
  row-gap: 60px;
  column-gap: 135px;
  box-sizing: border-box;
  padding-top: 60px;
  padding-right: 37px;
  padding-left: 60px;
  padding-bottom: 90px;
}
.buttonContainer {
  position: fixed;
  bottom: 40px;
  right: 45px;
  z-index: 1000;
}

.buttonContainer button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #353a33;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.4s;
}
.buttonContainer button:hover {
  background-color: #4a4f47;
  transform: scale(1.1);
}
.buttonContainer button svg {
  transition: transform 0.3s;
}

.fullWidth .leftContainer {
  display: none;
}
.fullWidth .rightTopContainer {
  display: none;
}

.fullWidth .logo {
  display: none;
}

.fullWidth .hamburger {
  display: none;
}
@media (max-width: 1850px) {
  .rightBottomContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* align-items: center; */

    /* width: 100%; */
    /* height: 100%; */
    background: #0a0c0a;
    border-radius: 50px;
    row-gap: 60px;
    column-gap: 30px;
    box-sizing: border-box;

   padding-top: 30px;
  padding-right: 25px;
  padding-left: 30px;
  padding-bottom: 30px;
  }

  .noBottomPadding {
    padding-bottom: 0 !important;
  }
  .fullHeight {
    min-height: 100vh;
    min-width: 100vw;
  }
}

@media (max-width: 1415px) {
  .rightBottomContainer {
    justify-content: center;
  }

  .gradient {
    overflow: hidden;
  }
}

@media (max-width: 1100px) {
  .logo {
    display: block;
    width: fit-content;
    justify-self: center;
    padding: 20px 0px;
  }
  .rightTopContainer {
    /* height: 115px; */
    /* border: 1px solid blue; */
  }
  .leftContainer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a0c0a;
    min-width: 250px;
    height: 100vh;
    transform: translateX(-100%);
    display: none;
  }

  .leftContainer.visible {
    display: block;
    transform: translateX(0);
  }

  .hamburger {
    display: block;
  }
  .rightBottomContainer {
    border-radius: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* width: 100%; */
    height: calc(100vh - 230px);
    background: #0a0c0a;
    border-radius: 50px;
    row-gap: 60px;
    /* column-gap: 135px; */
    box-sizing: border-box;
    padding-top: 30px;
    padding-right: 25px;
    padding-left: 30px;
    padding-bottom: 30px;
  }
  .rightContainer {
    margin-right: 0px;
    /* margin-top: 115px; */
    margin-bottom: 20px;
    padding: 0px;

    /* width: fit-content; */
    width: 100% !important;
    /* transition: all 0.3s ease-in-out; */

    /* padding */
  }
  .border {
    padding: 0 60px;
  }
}

@media (max-width: 950px) {
  .rightContainer {
    margin-right: 0px;
    /* margin-top: 115px; */
    margin-bottom: 20px;
    padding: 0px;

    /* width: fit-content; */
    width: 100% !important;
    /* transition: all 0.3s ease-in-out; */
  }
}

@media (max-width: 780px) {
  .border {
    padding: 0px 32px;
  }
  .gradient {
    border-radius: 25px;
  }

  .rightBottomContainer {
    display: flex;
    justify-content: center;
    border-radius: 25px;

    padding-top: 30px;
    padding-right: 25px;
    padding-left: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 580px) {
  .border {
    padding: 16px;
  }
  .gradient {
    border-radius: 25px;
  }

  .rightBottomContainer {
    border-radius: 25px;

    padding-top: 30px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 30px;
  }
}

/* prevvvvv
 */

@media (min-width: 1920px) {
  .border {
    /* height: 100%; */
  }
}

@media (max-height: 1100px) {
  .subContainer {
    height: 100vh;
  }
  .rightBottomContainer {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
    /* padding-bottom: 0px; */
    row-gap: 30px;
    justify-content: space-evenly;
  }
}
@media (max-height: 820px) {
  .subContainer {
    height: 100vh;
  }
  .rightBottomContainer {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
    /* padding-bottom: 0px; */
    row-gap: 30px;
    justify-content: space-evenly;
  }
}

@media (max-height: 620px) {
  .subContainer {
    height: 100% !important;
  }
  .rightBottomContainer {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    /* padding-bottom: 0px; */
  }
}

@media (min-height: 820px) {
  .mainContainer {
    /* height: 100vh; */

    justify-content: center;
  }

  .rightBottomContainer {
    /* height: calc(100vh - 185px); */
    overflow-y: scroll;
    overflow-x: hidden;
    /* padding-bottom: 0px; */
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

@media (min-height: 1120px) {
  .rightBottomContainer {
    /* height: calc(100vh - 270px) !important; */
    overflow-y: scroll;
    overflow-x: hidden;
    /* height: fit-content; */
    /* padding-bottom: 0px; */
  }
}
