/* ExpiryProgressBar.css */
.expiryProgressContainer {
    width: 100%;
    max-width: 800px;
    padding: 24px;
    background-color: #1a1a1a;
    border-radius: 8px;
    margin: 0 auto;
}
  
.expiryProgressHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
  
.daysText, .daysCount {
    color: white;
    font-size: 20px;
    font-weight: 500;
}
  
.progressBarContainer {
    position: relative;
    height: 8px;
    background-color: #333333;
    border-radius: 999px;
    overflow: hidden;
}
  
.progressBar {
    position: absolute;
    height: 100%;
    background-color: #a3e635; /* Lime color */
    border-radius: 999px;
    transition: width 0.3s ease-out;
}
  
.remainingMessage {
    margin-top: 8px;
    color: #9ca3af;
    text-align: center;
    font-size: 14px;
}
  
/* Optional: Add media queries for responsiveness */
@media (max-width: 480px) {
    .expiryProgressContainer {
        padding: 16px;
    }
  
    .daysText, .daysCount {
        font-size: 16px;
    }
  
    .remainingMessage {
        font-size: 12px;
    }
}